<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getMdrlsNow()" label-width="120px">
          <el-row>
            <el-col :md="8">
              <el-form-item label="材料编号:">
                <el-input size="small" v-model.trim="searchForm.mdrl_no" clearable placeholder="请填写材料编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="材料名称:">
                <el-input size="small" v-model.trim="searchForm.mdrl_name" clearable placeholder="请填写材料名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="材料类型:">
                <el-select size="small" v-model="searchForm.mdrl_type" placeholder="请选择材料类型" clearable>
                  <el-option v-for="item in mdrlType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="单据状态:">
                <el-select size="small" v-model="searchForm.status" placeholder="请选择单据状态" clearable>
                  <el-option v-for="item in statusOption" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="24">
              <el-form-item label="创建时间:">
                <div class="block vg_mr_32">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getMdrlsNow()" class="vg_ml_16">查询</el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"
          ><i class="el-icon-delete" :disabled="!btn.delete"></i> 删除</el-button
        >
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            class="vg_cursor"
            @sort-change="sortChange"
            ref="multiTable"
            :data="tableData"
            @selection-change="handleSelectionChange"
            @select="selectRows"
            @select-all="selectRows"
            @row-dblclick="dbClickJp"
            border
            v-loading="loadFlag"
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="材料编号" sortable prop="mdrl_no" />
            <el-table-column label="材料图片" align="center" v-if="!loadFlag">
              <template v-slot="scope">
                <el-popover v-if="scope.row.imge_url" placement="right" width="400" trigger="hover">
                  <div>
                    <el-image style="width: 100%" class="vd_popimg" :src="formatPic(scope, 'l')" fit="fill"> </el-image>
                  </div>
                  <el-image slot="reference" class="vd_elimg" :src="formatPic(scope, 's')" fit="fill"> </el-image>
                </el-popover>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="材料名称" prop="mdrl_name" show-overflow-tooltip />
            <el-table-column label="供应商" prop="supp_name" show-overflow-tooltip />
            <el-table-column label="材料类型">
              <template v-slot="scope">
                <span v-if="scope.row.mdrl_type >= 0">
                  {{ scope.row.mdrl_type | formatType }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="材料规格" prop="mdrl_width">
              <template v-slot="scope">
                <span v-if="scope.row.mdrl_spec">
                  {{ scope.row.mdrl_spec }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina" />
            <el-table-column label="创建时间" prop="create_time" :formatter="formatDate" show-overflow-tooltip />
            <el-table-column label="单据状态" :show-overflow-tooltip="true" align="center">
              <template v-slot="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">{{
                  helper.getStatusName(scope.row.status).name
                }}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination" />
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mdrlAPI } from '@api/modules/mdrl';
import { optnAPI } from '@api/modules/optn';
import { suppAPI } from '@api/modules/supp';
import pubPagination from '@/components/common/pubPagination.vue';
import publicTips from '@/views/component/publicTips.vue';
import helper from '@assets/js/helper.js';

export default {
  name: 'MdrlList',
  components: {
    pubPagination,
    publicTips
  },
  data() {
    return {
      tableData: [],
      searchForm: {},
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      mdrlType: [
        { id: 1, label: '主材料' },
        { id: 2, label: '辅材料' },
        { id: 3, label: '其他' }
      ],
      // 0=草拟 1=在批 2=生效
      statusOption: [
        { id: 0, label: '草拟' },
        { id: 1, label: '在批' },
        { id: 2, label: '生效' }
      ],
      imgNum: parseInt(Math.random() * 10000000000000),
      optnColorList: [],
      deptTypeList: [],
      compType: [],
      suppList: [],
      splitFlag: false,
      inputValue: ''
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/mdrl_add' || from.path === '/mdrl_edit') {
        this.initData();
      }
    }
  },
  filters: {
    // 材料类型
    formatType(row) {
      if (row === 1) {
        return '主材料';
      } else if (row === 2) {
        return '辅材料';
      } else {
        return '其他';
      }
    },
    formaUnitM(row) {
      return helper.haveFour(row) + '米';
    },
    formaUnitH(row) {
      return helper.reservedDigits(row) + '毫米';
    }
  },
  methods: {
    initData() {
      this.getMdrlsList();
      // this.getOptnColor()
      this.getOptnByPermId();
      // this.getCompType()
      // this.getSupp()
    },
    /**
     * 获取材料信息
     */
    getMdrlsList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      this.searchForm.start_time = timeNewVal.startTime;
      this.searchForm.end_time = timeNewVal.endTime;
      this.searchForm.page_no = this.currentPage;
      get(mdrlAPI.getMdrls, this.searchForm).then(res => {
        if (res.data.code === 0) {
          this.loading = false;
          this.tableData = res.data.data.list;
          this.totalPage = res.data.data.total;
          this.btn = res.data.data.btn;
          setTimeout(() => {
            this.$nextTick(() => {
              this.loadFlag = false;
            });
          }, 1000);
        }
      });
    },
    getOptnByPermId() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 }).then(res => {
        if (res.data.code === 0) {
          this.deptTypeList = res.data.data.form.optn_cntt_list;
        }
      });
    },
    getSupp() {
      get(suppAPI.getSuppsV1).then(res => {
        if (res.data.code === 0) {
          this.suppList = res.data.data;
        }
      });
    },
    /**
     * 刷新
     */
    buttonRefresh() {
      this.searchForm = { page_no: 1 };
      this.loadFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    /**
     * 查询方法
     */
    getMdrlsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getMdrlsList();
    },
    /**
     * 状态查询
     */
    getStatusVal(val) {
      this.searchForm.status = val;
    },
    /**
     * 多选
     */
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    /**
     * 判断是否可以删除
     */
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    /**
     * 双击row跳转到详情编辑
     */
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/mdrl_edit', { perm_id: permId, form_id: row.mdrl_id });
    },
    /**
     * 新增
     */
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/mdrl_add?perm_id=${permId}`);
    },
    /**
     * 分页查询
     */
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getMdrlsList();
    },
    /**
     * 材料单位转换
     */
    formaUnitP(row) {
      return this.helper.haveFour(row.mdrl_prod_price) + '元/' + row.mdrl_unit;
    },
    /**
     * 图片类型
     */
    formatPic(scope, type) {
      let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
      return this.helper.picUrl(scope.row.imge_url, type, updateTime);
    },
    /**
     * 时间转换
     */
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    /**
     * 未选中提示
     */
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'error', message: '此单据已生效或者正在审批中,无法删除！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    /**
     * 材料信息list删除提示
     */
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const ids = [];
        this.multiSelection.map(item => {
          ids.push(item.mdrl_id);
        });
        post(mdrlAPI.deleteMdrlByIds, { mdrl_id_list: ids }).then(res => {
          let mg = res.data.msg;
          if (res.data.code === 0) {
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            if (ids.length === this.tableData.length) {
              if (this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.$refs.pubPagination.currentPage = this.currentPage;
              }
            }
          }
          this.initData();
        });
      });
    },
    /**
     * 升序 降序
     */
    sortChange(val) {
      if (val.order === 'ascending') {
        this.searchForm.mdrl_sort = 'asc';
        this.searchForm.mdrl_field = val.prop;
      } else if (val.order === 'descending') {
        this.searchForm.mdrl_sort = 'desc';
        this.searchForm.mdrl_field = val.prop;
      } else {
        this.searchForm.mdrl_sort = '';
        this.searchForm.mdrl_field = '';
      }
      this.searchForm.page_no = 1;
      this.getMdrlsList();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  margin-top: 5px;
  width: 60px;
  height: 60px;
}
.vd_div {
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}
.vd_dis {
  display: flex;
}
.vd_popimg {
  width: 400px;
  height: 400px;
}
.vd_dis {
  display: flex;
  align-items: center;
}
.vd_right {
  text-align: right;
  padding-right: 10px;
}
</style>
