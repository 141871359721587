import topic from '../topic';
import { get } from '@api/request';

export const mdrlAPI = {
  getMdrls: topic.mdrlTopic + '/getMdrls',
  //getPlMdrls: topic.mdrlTopic + '/getPlMdrls',
  //getMdrlsInclusive: topic.mdrlTopic + '/getMdrlsInclusive',
  getMdrlById: topic.mdrlTopic + '/getMdrlById',
  addMdrl: topic.mdrlTopic + '/addMdrl',
  editMdrl: topic.mdrlTopic + '/editMdrl',
  deleteMdrlByIds: topic.mdrlTopic + '/deleteMdrlByIds',
  // getPushMdrls9002: topic.mdrlTopic + '/get_pushMdrls_9002',
  // updateScanById: topic.mdrlTopic + '/updateScanById',
  // judgeMdrlNo: topic.mdrlTopic + '/judgeMdrlNo',
  // getMdrlByInfo: topic.mdrlTopic + '/getMdrlByInfo',
  // getCountry: topic.mdrlTopic + '/getCountry',
  // editMdrlPriceByTerm: topic.mdrlTopic + '/editMdrlPriceByTerm',
  getMdrlNoTopMax: param => get(`${topic.mdrlTopic}/getMdrlNoTopMax`, param)
};
